import settings from 'bundle-text:../../settings.module.sass';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { CSSRulePlugin } from "gsap/CSSRulePlugin"


export default class Cover {
  covers: any;
  headerHeight: number = parseInt(settings['header-height']);

  constructor () {
    gsap.registerPlugin(ScrollTrigger, CSSRulePlugin)
    this.covers = document.querySelectorAll('.cover');
    this.initButtons();
    this.initAutoScrollWatcher();
    this.animationStory();
    
  }

  initButtons () {
    this.covers.forEach(cover => {
      let button = cover.querySelector('[data-hook=open-story-button]');

      if (button) {
        button.addEventListener('click', (e) => {
          e.preventDefault();
          this.growToFullscreen(cover);
          this.triggerStoryLoading(button);
        });
      }
    });
  }

  initAutoScrollWatcher() {
    window.addEventListener('autoscroll:active', event => {
      let firstCover = document.querySelector('.cover');
      firstCover.classList.add('cover--autoscroll');
    })
  }

  animationStory() {
    if (document.querySelector('.cover--story-1')) {
      let leftImg = document.querySelector('.cover__img--left');
      let rightImg = document.querySelector('.cover__img--right');
      let tl = gsap.timeline()

      tl.from(leftImg, {xPercent: -100, duration: 1})
      tl.from(rightImg, {xPercent: 100, duration: 1}, "-=.5")
    }
    if (document.querySelector('.cover--story-2')) {
      let bgImg = document.querySelector('.cover--story-2');
      let bgImgBefore = CSSRulePlugin.getRule('.cover--story-2::before');

      let tl = gsap.timeline()

      tl.from(bgImgBefore, {cssRule: {xPercent: -100}, duration: 1})
      tl.from(bgImg, {backgroundPosition: '100% 100%', duration: 1}, "<")
    }
    if (document.querySelector('.cover--story-3')) {
      let svgs = document.querySelectorAll('.cover--story-3 svg');
      let story3 = document.querySelector('.cover--story-3');

      let tl = gsap.timeline()

      let wifiLines = story3.querySelectorAll('#wifi path')
      let battery = story3.querySelectorAll('#battery path')
      let earth = story3.querySelector('#earth')
      let earthShadow = story3.querySelector('#earth-shadow')
      let leafLeft = story3.querySelector('#leave-left')
      let leafRight = story3.querySelector('#leave-right')
      let lamp = story3.querySelector('#lamp-blink')
      let machine = story3.querySelector('#machine-small')
      let flash = story3.querySelector('#flash')


      tl.from(svgs, {autoAlpha: 0, xPercent: -100, stagger: {each: 0.3, from: "start"}, duration: 1.5})

      gsap.fromTo(wifiLines, {autoAlpha: 0}, {autoAlpha: 1, stagger: {each: 1, from: 'end'}, repeat: -1, duration: 2, yoyo: true})
      gsap.fromTo(battery, {autoAlpha: 0}, {stagger: {each: .8, from: 'start'}, repeat: -1, autoAlpha: 1, ease: 'linear', yoyo: true})
      gsap.to(earth, {yPercent: -10, repeat: -1, ease: 'linear', yoyo: true, duration: 2})
      gsap.from(earthShadow, {opacity: .9, scale: .8, repeat: -1, ease: 'linear', yoyo: true, duration: 2, transformOrigin: '50% 50%'})
      gsap.from(leafLeft, {rotate: 10, delay: .5, repeat: -1, repeatDelay: 3, ease: 'bounce.out', yoyo: true, duration: 1, transformOrigin: '100% 100%'})
      gsap.to(lamp, {autoAlpha: 0, repeat: -1, ease: 'linear', yoyo: true, duration: .5})
      gsap.to(machine, {xPercent: 240, yPercent: 165, repeat: -1, repeatDelay: 2, ease: 'linear', yoyo: true, duration: 4})
      gsap.to(flash, {yPercent: -10, repeat: -1, ease: 'linear', yoyo: true, duration: 1})

    }
  }

  growToFullscreen(cover) {
    let coverViewportOffset = cover.getBoundingClientRect().top;
    let windowScrollY = window.pageYOffset;
    let headerHeight = this.headerHeight;
    let scrollPos = windowScrollY + coverViewportOffset - headerHeight

    window.scrollTo({
      top: scrollPos,
      behavior: 'smooth'
    })
    cover.classList.add('cover--animated', 'cover--full');
  }

  triggerStoryLoading(button) {
    let storyURL = button.getAttribute('href');

    button.classList.add('more-button--progress');
    window.setTimeout(() => {
      location.assign(`${storyURL}?as=1`);
    }, 1500)
  }
}
