import settings from 'bundle-text:../../settings.module.sass';
import I18N from '../i18n';

const i18n = new I18N();

const employees = {
  labels: [
    '2017',
    '2018',
    '2019',
    '2020',
    '2021'
  ],
  datasets: [
    {
      label: i18n.t('labels.employees'),
      unit: "FTE ⁴",
      backgroundColor: '#FFB900',
      data: [16248, 17877, 18381, 18103, 19103],
    }, {
      label: i18n.t('labels.thereof_germany'),
      unit: "FTE ⁴",
      backgroundColor: '#636b6e',
      indent: true,
      data: [6962, 7378, 7635, 7577, 7995],
    }, {
      label: i18n.t('labels.thereof_abroad'),
      unit: "FTE ⁴",
      backgroundColor: '#b1b5b7',
      indent: true,
      data: [9286, 10499, 10746, 10526, 11108],
    }
  ]
};

const employeesOptions = {
  scales: {
    x: {
      ticks: {
        font: {
          size: 24,
          weight: 500
        }
      },
      grid: {
        borderWidth: 2,
        display: false,
        borderColor: '#3c464b'
      }
    },
    yLeft: {
      type: 'linear',
      position: 'left',
      grid: {
        borderWidth: 2,
        borderDash: [2, 2],
        tickBorderDash: [2, 2]
      },
      title: {
        display: true,
        text: "FTE ⁴",
        font: {
          size: 14,
          family: settings['font-stack-sans'],
          weight: 500
        }
      },
      ticks: {
        maxTicksLimit: 6,
      }
    }
  },
  footnotes: [
    i18n.t('footnotes.4')
  ]
}

export { employees, employeesOptions }
