import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import settings from 'bundle-text:../../settings.module.sass';

export default class Strategy {
  headerHeight: number = parseInt(settings['header-height']);
  figureWrapper: any;
  bannerContainer: any;

  constructor() {
    let banner = document.querySelector('.banner')
    if (!banner) return

    gsap.registerPlugin(ScrollTrigger)
    this.figureWrapper = document.querySelector('.figure__wrapper')
    let figures = this.figureWrapper.querySelectorAll('.figure')

    let timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.figureWrapper,
        start: `top center`,
      }
    })

    this.bannerContainer = document.querySelector('.banner__container')
    let timelineSticky = gsap.timeline({
      scrollTrigger: {
        trigger: this.bannerContainer,
        pin: banner,
        pinSpacing: false,
        start: "top 90px",
        end: "bottom-=180px top",
        toggleClass: "is-sticky"
      }
    })

    figures.forEach(figure => {
      timeline.fromTo(figure,{
        yPercent: gsap.utils.random([-75, -50, -25, 25, 50, 75], true),
        autoAlpha: 0
      }, {
        yPercent: 0,
        autoAlpha: 1
      }, '-=.25')
    })
  }
}
