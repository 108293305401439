import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js"

export default class Satellite {
	satellites: any;

  constructor() {
	this.satellites = document.querySelectorAll('.js-satellite')
    this.satellites.forEach(satellite => {
    	this.lines = satellite.querySelectorAll('#first-line, #second-line')
    	this.animation()

    })
  }

  animation() {
  	gsap.fromTo(this.lines, {autoAlpha: 0}, {autoAlpha: 1, stagger: {each: 1, from: 'end'}, repeat: -1, duration: 2, yoyo: true})

  }
}

