import settings from 'bundle-text:../../settings.module.sass';
import I18N from '../i18n';

const i18n = new I18N();

const revenue = {
  labels: [
    '2017',
    '2018',
    '2019',
    '2020',
    '2021'
  ],
  datasets: [
    {
      label: i18n.t('labels.revenue'),
      unit: i18n.t('units.mio_euro'),
      backgroundColor: '#636b6e',
      borderColor: '#636b6e',
      data: [3435, 3796, 4073, 3809, 4240],
      order: 2
    }, {
      label: i18n.t('labels.thereof_germany'),
      unit: i18n.t('units.mio_euro'),
      backgroundColor: '#b1b5b7',
      borderColor: '#b1b5b7',
      indent: true,
      data: [851, 900, 966, 917, 1014],
      order: 2
    }, {
      label: i18n.t('labels.thereof_abroad'),
      unit: i18n.t('units.mio_euro'),
      backgroundColor: '#000000',
      borderColor: '#000000',
      indent: true,
      data: [2584, 2896, 3107, 2892, 3226],
      order: 2
    },
    {
      type: 'line',
      label: i18n.t('labels.foreign_ratio'),
      unit: '%',
      backgroundColor: '#FFB900',
      borderColor: '#FFB900',
      data: [75, 76, 76, 76, 76],
      yAxisID: 'yRight',
      order: 1
    }
  ]
};

const revenueOptions = {
  scales: {
    x: {
      ticks: {
        font: {
          size: 24,
          weight: 500
        }
      },
      grid: {
        borderWidth: 2,
        display: false,
        borderColor: '#3c464b'
      }
    },
    yLeft: {
      type: 'linear',
      position: 'left',
      grid: {
        borderWidth: 2,
        borderDash: [2, 2],
        tickBorderDash: [2, 2]
      },
      title: {
        display: true,
        text: `${i18n.t('units.mio_euro')} (${i18n.t('misc.bars')})`,
        font: {
          size: 14,
          family: settings['font-stack-sans'],
          weight: 500
        }
      },
      ticks: {
        maxTicksLimit: 6,
      }
    },
    yRight: {
      type: 'linear',
      position: 'right',
      min: 74,
      max: 77,
      grid: {
        borderWidth: 2,
        color: '#ffb900',
        borderColor: '#ffb900',
      },
      title: {
        display: true,
        text: `in % (${i18n.t('misc.lines')})`,
        font: {
          size: 14,
          family: settings['font-stack-sans'],
          weight: 500
        }
      },
      ticks: {
        maxTicksLimit: 6
      }
    }
  }
}

export { revenue, revenueOptions }
