import settings from 'bundle-text:../../settings.module.sass';
import I18N from '../i18n';

const i18n = new I18N();

const orders = {
  labels: [
    '2017',
    '2018',
    '2019',
    '2020',
    '2021'
  ],
  datasets: [
    {
      type: 'bar',
      label: i18n.t('labels.incoming_orders'),
      unit: i18n.t('units.units'),
      backgroundColor: '#636B6E',
      borderColor: '#636B6E',
      data: [123500, 131000, 121900, 111400, 162400],
      yAxisID: 'yLeft',
      order: 2
    }, {
      type: 'line',
      label: i18n.t('labels.incoming_orders'),
      unit: i18n.t('units.mio_euro'),
      backgroundColor: '#FFB900',
      borderColor: '#FFB900',
      data: [3560, 3971, 3922, 3777, 4868],
      yAxisID: 'yRight',
      order: 1
    }, {
      type: 'line',
      label: i18n.t('labels.orders_on_hand'),
      unit: i18n.t('units.mio_euro'),
      backgroundColor: '#BA8800',
      borderColor: '#BA8800',
      data: [692, 907, 787, 821, 1519],
      yAxisID: 'yRight',
      order: 1
    }
  ]
};

const ordersOptions = {
  scales: {
    x: {
      ticks: {
        font: {
          size: 24,
          weight: 500
        }
      },
      grid: {
        borderWidth: 2,
        display: false,
        borderColor: '#3c464b'
      }
    },
    yLeft: {
      type: 'linear',
      position: 'left',
      grid: {
        borderWidth: 2,
        borderDash: [2, 2],
        tickBorderDash: [2, 2]
      },
      title: {
        display: true,
        text: `${i18n.t('units.units')} (${i18n.t('misc.bars')})`,
        font: {
          size: 14,
          family: settings['font-stack-sans'],
          weight: 500
        }
      },
      ticks: {
        maxTicksLimit: 6,
      }
    },
    yRight: {
      type: 'linear',
      position: 'right',
      grid: {
        borderWidth: 2,
        color: '#ffb900',
        borderColor: '#ffb900',
      },
      title: {
        display: true,
        text: 'Mio. € (Linien)',
        text: `${i18n.t('units.mio_euro')} (${i18n.t('misc.lines')})`,
        font: {
          size: 14,
          family: settings['font-stack-sans'],
          weight: 500
        }
      },
      ticks: {
        maxTicksLimit: 6
      }
    }
  }
}

export { orders, ordersOptions }
