import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class Animation {
  constructor() {
    let animatedElements = [...document.querySelectorAll('[data-animate]')]
    if (animatedElements.length === 0) return

    gsap.registerPlugin(ScrollTrigger)

    window.onload = () => {
      this.initLeftSlideIn()
      this.initRightSlideIn()
      this.initStaggerLeftSlideIn()
      this.initScrollSwap()
    }
  }

  initLeftSlideIn() {
    let elementsToAnimate = [...document.querySelectorAll('[data-animate=left-slide-in]')]
    if (elementsToAnimate.length === 0) return

    elementsToAnimate.forEach((e) => {
      gsap.from(e, {
        xPercent: -120,
        opacity: 0,
        duration: 1.5,
        ease: "power1.inOut",
        scrollTrigger: {
          trigger: e,
          start: 'top 80%',
          toggleActions:"play none none none",
        },
      })
    })
  }

  initStaggerLeftSlideIn() {
    let elementsToAnimate = [...document.querySelectorAll('[data-animate=left-stagger-slide-in]')] 
    if (elementsToAnimate.length === 0) return

    let animationContainer = [...document.querySelectorAll('[data-animate=left-stagger-slide-in-container]')]

      gsap.from(elementsToAnimate, {
        xPercent: -120,
        opacity: 0,
        duration: 1.5,
        ease: "power1.inOut",
        stagger: 0.2,
        scrollTrigger: {
          trigger: animationContainer,
          start: 'top 80%',
          toggleActions:"play none none none",
        },
      })
  }

  initScrollSwap() {
    let elementToAnimate = document.querySelector('[data-animate=scroll-swap]')
    if (!elementToAnimate) return

    const swapTimeline = gsap.timeline();
    const stickyContainer = elementToAnimate.querySelector('.strip__sticky');
    const endTrigger = elementToAnimate.querySelector('[data-hook=scroll-swap-end-trigger]');
    const stickyItems = elementToAnimate.querySelectorAll('.strip__sticky-item');

    swapTimeline.add(`waypoint-0`);

    stickyItems.forEach((stickyItem, index) => {

      if (index !== 0) {
        swapTimeline.from(stickyItem, {
          onStart: () => {
            stickyItem.classList.add('strip__sticky-item--active')
          },
          onReverseComplete: () => {
            stickyItem.classList.remove('strip__sticky-item--active')
          },
          duration: .1
        }, `waypoint-${index}`).to(stickyItem, {
          duration: 50
        });
      } else {
        stickyItem.classList.toggle('strip__sticky-item--active')
      }

      swapTimeline.add(`waypoint-${index+1}`);

      if (index !== stickyItems.length - 1) {
        swapTimeline.to(stickyItem, {
          onStart: () => {
            stickyItem.classList.remove('strip__sticky-item--active')
            stickyItem.classList.add('strip__sticky-item--fadeout')
          },
          onReverseComplete: () => {
            stickyItem.classList.remove('strip__sticky-item--fadeout')
            stickyItem.classList.add('strip__sticky-item--active')
          },
          duration: .1
        });
      }
    });

    ScrollTrigger.create({
      animation: swapTimeline,
      trigger: stickyContainer,
      endTrigger: endTrigger,
      start: 'center 50%',
      end: 'bottom 80%',
      pin: stickyContainer,
      scrub: 1
    });

  }

  initRightSlideIn() {
    let elementsToAnimate = [...document.querySelectorAll('[data-animate=right-slide-in]')]
    if (elementsToAnimate.length === 0) return

    elementsToAnimate.forEach((e) => {
      gsap.from(e, {
        xPercent: 80,
        opacity: 0,
        duration: 1.5,
        ease: "power1.inOut",
        scrollTrigger: {
          trigger: e,
          start: 'top 80%',
          toggleActions:"play none none none",
        },
      })
    })
  }
}
