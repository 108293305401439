import settings from 'bundle-text:../../settings.module.sass';
import I18N from '../i18n';

const i18n = new I18N();

const overview = {
  labels: [
    '2017',
    '2018',
    '2019',
    '2020',
    '2021'
  ],
  datasets: [
    {
      label: i18n.t('labels.incoming_orders'),
      unit: i18n.t('units.units'),
      data: [123500, 131000, 121900, 111400, 162400],
    },
    {
      label: '',
      unit: i18n.t('units.mio_euro'),
      data: [3560, 3971, 3922, 3777, 4868],
    },
    {
      label: i18n.t('labels.orders_on_hand'),
      unit: i18n.t('units.mio_euro'),
      data: [692, 907, 787, 821, 1519],
    },
    {
      label: i18n.t('labels.revenue'),
      unit: i18n.t('units.mio_euro'),
      data: [3435, 3796, 4073, 3809, 4240],
    },
    {
      label: i18n.t('labels.thereof_germany'),
      unit: i18n.t('units.mio_euro'),
      indent: true,
      data: [851, 900, 966, 917, 1014],
    },
    {
      label: i18n.t('labels.thereof_abroad'),
      unit: i18n.t('units.mio_euro'),
      indent: true,
      data: [2584, 2896, 3107, 2892, 3226],
    },
    {
      label: i18n.t('labels.foreign_ratio'),
      unit: '%',
      data: [75, 76, 76, 76, 76],
    },
    {
      label: i18n.t('labels.ebit'),
      unit: i18n.t('units.mio_euro'),
      data: [259, 275, 263, 218, 360],
    },
    {
      label: i18n.t('labels.ebit_ros'),
      unit: '%',
      data: [7.5, 7.2, 6.4, 5.7, 8.5],
    },
    {
      label: `${i18n.t('labels.roce')} ¹`,
      unit: '%',
      data: [17, 16, 14, 14, 20],
    },
    {
      label: `${i18n.t('labels.roce_new')} ²`,
      unit: '%',
      data: ["-", "-", "-", 11, 20],
    },
    {
      label: i18n.t('labels.ebt'),
      unit: i18n.t('units.mio_euro'),
      data: [243, 249, 242, 200, 349],
    },
    {
      label: i18n.t('labels.ebt_ros'),
      unit: '%',
      data: [7.1, 6.6, 5.9, 5.3, 8.2],
    },
    {
      label: i18n.t('labels.profit_or_loss'),
      unit: i18n.t('units.mio_euro'),
      data: [182, 176, 177, 151, 267],
    },
    {
      label: `${i18n.t('labels.capital_expenditure')} ³`,
      unit: i18n.t('units.mio_euro'),
      data: [88, 106, 157, 75, 71],
    },
    {
      label: i18n.t('labels.rnd_expenditure'),
      unit: i18n.t('units.mio_euro'),
      data: [77, 84, 86, 89, 102],
    },
    {
      label: i18n.t('labels.balance_sheet_total'),
      unit: i18n.t('units.mio_euro'),
      data: [4130, 4746, 5231, 5411, 5769],
    },
    {
      label: i18n.t('labels.shareholders_equity'),
      unit: i18n.t('units.mio_euro'),
      data: [1244, 1362, 1488, 1546, 1803],
    },
    {
      label: i18n.t('labels.thereof_subscribed_capital'),
      unit: i18n.t('units.mio_euro'),
      indent: true,
      data: [102, 102, 102, 102, 102],
    },
    {
      label: i18n.t('labels.employees'),
      unit: "FTE ⁴",
      data: [16248, 17877, 18381, 18103, 19103],
    },
    {
      label: i18n.t('labels.thereof_germany'),
      unit: "FTE ⁴",
      indent: true,
      data: [6962, 7378, 7635, 7577, 7995],
    },
    {
      label: i18n.t('labels.thereof_abroad'),
      unit: "FTE ⁴",
      indent: true,
      data: [9286, 10499, 10746, 10526, 11108],
    },
    {
      label: `${i18n.t('labels.earnings_preferred_share')} ⁵`,
      unit: "€",
      minimumFractionDigits: 2,
      data: [1.80, 1.73, 1.75, 1.49, 2.62],
    },
    {
      label: i18n.t('labels.dividend_ordinary_share'),
      unit: "€",
      minimumFractionDigits: 2,
      data: [0.48, 0.48, 0.46, 0.41, 0.66],
      footnote: [null, null, null, null, '⁶'],
    },
    {
      label: i18n.t('labels.dividend_preferred_share'),
      unit: "€",
      minimumFractionDigits: 2,
      data: [0.50, 0.50, 0.48, 0.43, 0.68],
      footnote: [null, null, null, null, '⁶'],
    }
  ]
};

const overviewOptions = {
  footnotes: [
    i18n.t('footnotes.1'),
    i18n.t('footnotes.2'),
    i18n.t('footnotes.3'),
    i18n.t('footnotes.4'),
    i18n.t('footnotes.5'),
    i18n.t('footnotes.6')
  ]
}

export { overview, overviewOptions }
