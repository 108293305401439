export default class Showcase {
  showcase: any;
  video: any;
  videoWatcher: any;
  interval: number = 100;
  fadeOutTime: number = 6.8;
  fadeInTime: number = 10.7;

  constructor () {
    this.showcase = document.querySelector('.showcase');

    if (!this.showcase) return;

    this.initVideo();
    this.initMuteSwitch();
  }

  initVideo() {
    this.video = this.showcase.querySelector('.showcase__video');

    // if(this.video) {
    //   this.videoWatcher = setInterval(() => {
    //     let currentTime = this.video.currentTime;
    //     if (currentTime) this.updateUIState(currentTime);
    //   }, this.interval)
    // }
  }

  initMuteSwitch() {
    let muteSwitch = this.showcase.querySelector('.showcase__mute-switch')

    muteSwitch.addEventListener('click', (e) => {
      this.video.muted = !this.video.muted
      muteSwitch.classList.toggle('showcase__mute-switch--un-muted')
      muteSwitch.blur()
    })
  }

  updateUIState (currentTime) {
    let inFadedTimeframe = (currentTime >= this.fadeOutTime) && (currentTime < this.fadeInTime);
    let fadedModifierActive = this.showcase.classList.contains('showcase--faded');

    if (!fadedModifierActive && inFadedTimeframe) this.showcase.classList.add('showcase--faded');
    if (fadedModifierActive && !inFadedTimeframe) this.showcase.classList.remove('showcase--faded');
  }
}
