import settings from 'bundle-text:../../settings.module.sass';
import I18N from '../i18n';

const i18n = new I18N();

const incomeStatement = {
  labels: [
    '2017',
    '2018',
    '2019',
    '2020',
    '2021'
  ],
  datasets: [
    {
      label: i18n.t('labels.ebit'),
      unit: i18n.t('units.mio_euro'),
      backgroundColor: '#636b6e',
      borderColor: '#636b6e',
      data: [259, 275, 263, 218, 360],
      order: 10
    }, {
      type: 'line',
      label: i18n.t('labels.ebit_ros'),
      unit: '%',
      backgroundColor: '#FFB900',
      borderColor: '#FFB900',
      data: [7.5, 7.2, 6.4, 5.7, 8.5],
      yAxisID: 'yRight',
      order: 0
    }, {
      type: 'line',
      label: `${i18n.t('labels.roce')} ¹`,
      unit: '%',
      backgroundColor: '#FFDD96',
      borderColor: '#FFDD96',
      data: [17, 16, 14, 14, 20],
      yAxisID: 'yRight',
      order: 1,
    }, {
      type: 'line',
      label: `${i18n.t('labels.roce_new')} ²`,
      unit: '%',
      backgroundColor: '#ffcb5f',
      borderColor: '#ffcb5f',
      data: ['-', '-', '-', 11, 20],
      yAxisID: 'yRight',
      order: 2,
    }, {
      label: i18n.t('labels.ebt'),
      unit: i18n.t('units.mio_euro'),
      backgroundColor: '#b1b5b7',
      borderColor: '#b1b5b7',
      data: [243, 249, 242, 200, 349],
      order: 11
    }, {
      type: 'line',
      label: i18n.t('labels.ebt_ros'),
      unit: '%',
      backgroundColor: '#BA8800',
      borderColor: '#BA8800',
      data: [7.1, 6.6, 5.9, 5.3, 8.2],
      yAxisID: 'yRight',
      order: 3
    }, {
      label: i18n.t('labels.profit_or_loss'),
      unit: i18n.t('units.mio_euro'),
      backgroundColor: '#000000',
      borderColor: '#000000',
      data: [182, 176, 177, 151, 267],
      order: 12
    }
  ]
};

const incomeStatementOptions = {
  scales: {
    x: {
      ticks: {
        font: {
          size: 24,
          weight: 500
        }
      },
      grid: {
        borderWidth: 2,
        display: false,
        borderColor: '#3c464b'
      }
    },
    yLeft: {
      type: 'linear',
      position: 'left',
      grid: {
        borderWidth: 2,
        borderDash: [2, 2],
        tickBorderDash: [2, 2]
      },
      title: {
        display: true,
        text: `${i18n.t('units.mio_euro')} (${i18n.t('misc.bars')})`,
        font: {
          size: 14,
          family: settings['font-stack-sans'],
          weight: 500
        }
      },
      ticks: {
        maxTicksLimit: 6,
      }
    },
    yRight: {
      type: 'linear',
      position: 'right',
      max: 25,
      grid: {
        borderWidth: 2,
        color: '#ffb900',
        borderColor: '#ffb900',
      },
      title: {
        display: true,
        text: `in % (${i18n.t('misc.lines')})`,
        font: {
          size: 14,
          family: settings['font-stack-sans'],
          weight: 500
        }
      },
      ticks: {
        maxTicksLimit: 6
      }
    }
  },
  footnotes: [
    i18n.t('footnotes.1'),
    i18n.t('footnotes.2'),
  ]
}

export { incomeStatement, incomeStatementOptions }
