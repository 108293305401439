import Carousel from './scripts/components/carousel'
import Cover from './scripts/components/cover'
import Drawer from './scripts/components/drawer'
import Header from './scripts/components/header'
import Interview from './scripts/components/interview'
import Satellite from './scripts/components/satellite'
import Showcase from './scripts/components/showcase'
// import Timeline from './scripts/components/timeline'
import Video from './scripts/components/video'

import './scripts/components/timeline-scroll'
import './scripts/components/charts'

import '@lottiefiles/lottie-player'

import Animation from './scripts/animation'
import Graphic from './scripts/components/graphic'
import ScrollTo from './scripts/scroll-to'
import PDFLinks from './scripts/pdf-links'
import ViewportWatcher from './scripts/viewport-watcher'
import Strategy from "./scripts/components/strategy";
import Accordion from "./scripts/components/accordion";
import Share from "./scripts/components/share";
// import Lottie from "./scripts/components/lottie";

const carousel = new Carousel()
const cover = new Cover()
const drawer = new Drawer()
const header = new Header()
const interview = new Interview()
const showcase = new Showcase()
// const timeline = new Timeline()
const video = new Video()

const animation = new Animation()
const graphic = new Graphic()
const scrollto = new ScrollTo()
const pdflinks = new PDFLinks()
const viewportwatcher = new ViewportWatcher()
const strategy = new Strategy()
const satellite = new Satellite()
const accordion = new Accordion()
const share = new Share()
// const lottie = new Lottie()

