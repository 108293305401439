import MQ from './lib/mq'
import { debounced } from './lib/utils'

export default class ViewportWatcher {
  mq: MQ;
  viewMode: string;
  resizeEvent: Event;
  resizedToMobileEvent: Event;
  resizedToStandardEvent: Event;

  constructor() {
    this.mq = new MQ();
    this.viewMode = this.mq.until('tablet-ls') ? 'mobile' : 'standard';

    this.resizeEvent = new Event('viewport:resized');
    this.resizedToMobileEvent = new Event('viewport:resized-to:mobile');
    this.resizedToStandardEvent = new Event('viewport:resized-to:standard');

    this.initResizeListener();
  }

  initResizeListener() {
    window.addEventListener('resize', debounced(200, () => {
      window.dispatchEvent(this.resizeEvent);

      if (this.mq.until('tablet-ls') && this.viewMode === 'standard') {
        this.viewMode = 'mobile';
        window.dispatchEvent(this.resizedToMobileEvent);
      }

      if (this.mq.from('tablet-ls') && this.viewMode === 'mobile') {
        this.viewMode = 'standard';
        window.dispatchEvent(this.resizedToStandardEvent);
      }
    }))
  }
}
