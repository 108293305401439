import Swiper, {Navigation, Pagination} from 'swiper';
import Plyr from 'plyr';

Swiper.use([Navigation, Pagination]);
import 'swiper/swiper-bundle.min.css';

class Carousel {
  plyrInstances: any[];

  constructor() {
    let carousel = document.querySelector('.js-carousel')
    if (!carousel) return

    window.addEventListener('load', (event) => {
      if (carousel.getAttribute('data-type') == 'products')
        this.initProduct()
      else if (carousel.getAttribute('data-type') == 'videos')
        this.initVideos()
      else
        this.init();

      window.setTimeout(function(){
        carousel.classList.add('carousel--initialized');
      }, 500)
    });
  }

  init() {
    const attributes = document.querySelector(".swiper__pagination");

    if (attributes && attributes.hasAttribute("data-titles")) {
      const result = attributes.getAttribute("data-titles");
      const titles = JSON.parse(result);

      const swiper = new Swiper(".js-carousel", {
        direction: "horizontal",
        loop: false,
        slidesPerView: 1,
        // autoHeight: true,
        navigation: {
          nextEl: '.swiper__next',
          prevEl: '.swiper__prev',
        },
        pagination: {
          el: '.swiper__pagination',
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (titles[index]) + '</span>';
          },
        },
      })
    }
  }

  initProduct() {
    const swiper = new Swiper(".js-carousel", {
      direction: "horizontal",
      loop: true,
      shortSwipes: false,
      slidesPerView: 1,
      centeredSlides: true,
      autoHeight: true,
      slideToClickedSlide: true,
      // pagination: {
      //   el: '.swiper__pagination',
      //   type: "bullets",
      //   clickable: true
      // },
      navigation: {
        nextEl: '.swiper__next',
        prevEl: '.swiper__prev',
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 5,
          loop: false,
          initialSlide: 3
        },
        1440: {
          slidesPerView: 7
        }
      }
    })
  }

  initVideos() {
    this.plyrInstances = []

    let videos = document.querySelectorAll('[data-hook=video-player-slider]');

    let imageUrl = new URL(
      '/node_modules/plyr/dist/plyr.svg',
      import.meta.url
    );

    const swiper = new Swiper(".js-carousel", {
      direction: "horizontal",
      loop: true,
      shortSwipes: false,
      slidesPerView: 1,
      centeredSlides: true,
      autoHeight: true,
      slideToClickedSlide: true,
      // pagination: {
      //   el: '.swiper__pagination',
      //   type: "bullets",
      //   clickable: true
      // },
      navigation: {
        nextEl: '.swiper__next',
        prevEl: '.swiper__prev',
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
      },
      on: {
        'beforeInit': () => {
          videos.forEach((video, index) => {
            this.plyrInstances[index] = new Plyr(video, {
              iconUrl: imageUrl,
              controls: ['play-large', 'play', 'progress', 'current-time', 'fullscreen'],
            });
          })
          this.resetAllVideos()
        },
        'slideChange': (swiper) => {
          this.resetAllVideos()
        }
      }
    })
  }

  resetAllVideos() {
    this.plyrInstances.forEach((plyrInstance) => {
      if (plyrInstance.playing || plyrInstance.paused) plyrInstance.stop();
    })
  }
}

export default Carousel;
