import Chart from 'chart.js/auto'
import MQ from '../../scripts/lib/mq'
import I18N from '../../scripts/i18n';

Chart.defaults.elements.bar.borderWidth = 0;
Chart.defaults.elements.line.borderWidth = 3;
Chart.defaults.elements.point.pointStyle = 'rectRounded';
Chart.defaults.elements.point.pointRadius = 3;
Chart.defaults.elements.point.radius = 5;
Chart.defaults.plugins.legend.labels.boxWidth = 20;
Chart.defaults.plugins.legend.labels.boxHeight = 20;
Chart.defaults.plugins.legend.position = 'top';
Chart.defaults.plugins.legend.align = 'start';
Chart.defaults.plugins.legend.labels.usePointStyle = true;
Chart.defaults.plugins.legend.labels.pointStyle = 'rectRounded';
Chart.defaults.color = '#000000';
Chart.defaults.font.size = 16
Chart.defaults.font.weight = 700
Chart.defaults.borderColor = '#b1b5b7'

export default class DDKeyFigures {
  constructor (data = null, config = {}) {
    this.container = document.querySelector('.dd-keyfigures');
    this.data = data;
    this.config = config;

    if (!this.container || !data) return

    this.sections = this.container.querySelectorAll('.dd-keyfigures__section');
    this.tabs = this.container.querySelectorAll('.dd-keyfigures__tab');
    this.modeButtons = this.container.querySelectorAll('.dd-keyfigures__mode');
    this.charts = {}
    this.revertToChartMode = false
    this.i18n = new I18N();
    this.mq = new MQ();

    this.initDefaults();
    this.initTabs();
    this.initModeswitch();
    this.initCharts();
    this.initChartsResponsivity();
    this.initTables();
    this.initFootnotes();
    this.initDownloads();
    this.initCloseButton();
    this.initYearFilters();
  }

  initDefaults() {
    if (this.config['font-family']) Chart.defaults.font.family = this.config['font-family'];
  }

  initTabs() {
    this.tabs.forEach(tab => {
      tab.addEventListener('click', e => {
        e.preventDefault();
        this.activateTab(tab);
      });
    });
  }

  initModeswitch() {
    this.modeButtons.forEach(button => {
      button.addEventListener('click', e => {
        e.preventDefault();
        this.activateMode(button.dataset.mode);
      });
    });

    this.activateMode(this.modeButtons[0].dataset.mode);
  }

  initCharts() {
    this.sections.forEach(section => {
      let sectionID = section.getAttribute('id');
      let chartCanvas = section.querySelector('.dd-keyfigures__chart canvas');
      let data = Object.assign({}, this.data[sectionID])

      this.charts[sectionID] = new Chart(chartCanvas, data);
    })
  }

  updateChartSettings(xAxisFontSize, defaultFontSize) {
    Chart.defaults.font.size = defaultFontSize;
    this.sections.forEach(section => {
      let sectionID = section.getAttribute('id');
      if (sectionID != 'overview') {
        let chart = this.charts[sectionID];
        chart.config.options.scales.x.ticks.font.size = xAxisFontSize
        chart.update();
      }
    })
  }

  initChartsResponsivity() {
    let viewMode = this.mq.until('desktop') ? 'mobile' : 'standard';
    let fontSizes = { 'mobile': 11, 'standard': 16 };
    let xAxisFontSizes = { 'mobile': 14, 'standard': 24 };

    this.updateChartSettings(xAxisFontSizes[viewMode], fontSizes[viewMode]);

    window.addEventListener('viewport:resized-to:mobile', () => {
      this.updateChartSettings(xAxisFontSizes['mobile'], fontSizes['mobile']);
    });
    window.addEventListener('viewport:resized-to:standard', () => {
      this.updateChartSettings(xAxisFontSizes['standard'], fontSizes['standard']);
    });
  }

  initTables() {
    this.sections.forEach(section => {
      let sectionID = section.getAttribute('id');
      let sectionData = this.data[sectionID].data;
      let tableHead = section.querySelector('.dd-keyfigures__data-table thead');
      let tableBody = section.querySelector('.dd-keyfigures__data-table tbody');
      let headRowTemplate = this.container.querySelector('.dd-keyfigures__template-row-head')
      let rowTemplate = this.container.querySelector('.dd-keyfigures__template-row')

      let headRowCells = headRowTemplate.content.querySelectorAll('th')
      let rowLabel = rowTemplate.content.querySelector('th')
      let rowCells = rowTemplate.content.querySelectorAll('td')
      let rowUnit = rowCells[0]

      sectionData.labels.forEach((label, index) => {
        headRowCells[index + 2].textContent = label
      })

      let headRow = document.importNode(headRowTemplate.content, true);
      tableHead.appendChild(headRow);

      let row

      sectionData.datasets.forEach(dataset => {
        let options = {}
        let localeID = this.i18n.getLocaleID()

        if (dataset.minimumFractionDigits !== null) options = { ...options, ...{ minimumFractionDigits: dataset.minimumFractionDigits } }

        rowLabel.textContent = dataset.label
        if (dataset.indent) rowLabel.classList.add('dd-keyfigures__table-cell-indented')
        else rowLabel.classList.remove('dd-keyfigures__table-cell-indented')
        rowUnit.textContent = dataset.unit

        dataset.data.forEach((value, index) => {
          let cellContent = (typeof value === 'string') ? value : Intl.NumberFormat(localeID, options).format(value)
          if (dataset.footnote !== undefined) if (dataset.footnote[index]) cellContent += ` ${dataset.footnote[index]}`
          rowCells[index + 1].textContent = cellContent
        })


        row = document.importNode(rowTemplate.content, true);
        tableBody.appendChild(row);
      })
    })
  }

  initDownloads() {
    this.sections.forEach(section => {
      let sectionID = section.getAttribute('id');
      let downloadsContainer = section.querySelector('.dd-keyfigures__downloads-buttons');
      let tpl = document.querySelector('.dd-keyfigures__template-downloads-buttons');
      let downloadsButtons = tpl.content.querySelectorAll(`[data-section=${sectionID}] .dd-keyfigures__downloads-button`);

      downloadsButtons.forEach(downloadsButton => {
        downloadsContainer.appendChild (downloadsButton);
      })
    });
  }

  initFootnotes() {
    this.sections.forEach(section => {
      let sectionID = section.getAttribute('id');
      let sectionOptions = this.data[sectionID].options;
      let footnoteContainer = section.querySelector('.dd-keyfigures__footnotes');

      if (sectionOptions.footnotes) {
        let sectionFootnotes = sectionOptions.footnotes.filter(footnote => footnote !== false).join("<br>");
        footnoteContainer.innerHTML = sectionFootnotes;
      }
    })
  }

  initCloseButton() {
    let closeButton = this.container.querySelector('.dd-keyfigures__close-button');

    closeButton.addEventListener('click', () => {
      this.deactivateActiveSection();
      this.container.classList.remove('dd-keyfigures--active');
      this.activateMode('chart');
    })
  }

  activateMode(mode) {
    this.modeButtons.forEach(button => {
      let operation = (button.dataset.mode == mode) ? 'add' : 'remove';
      this.container.classList[operation](`dd-keyfigures--mode-${button.dataset.mode}`);
      button.classList[operation]('dd-keyfigures__mode--active');
    })
    this.container.dataset.activeMode = mode;
  }

  activateTab(tab) {
    this.deactivateActiveSection();

    let targetID = tab.getAttribute('href').split('#')[1];
    let targetElement = document.querySelector(`#${targetID}`);

    tab.classList.add('dd-keyfigures__tab--active');
    targetElement.classList.add('dd-keyfigures__section--active');
    this.container.classList.add('dd-keyfigures--active')

    if (targetID === 'overview') {
      this.revertToChartMode = (this.container.dataset.activeMode === 'chart');
      this.activateMode('table');
      this.container.classList.add('dd-keyfigures--overview');
    } else {
      if (this.revertToChartMode) {
        this.activateMode('chart');
        this.revertToChartMode = false;
      }
      this.container.classList.remove('dd-keyfigures--overview');
    }
  }

  deactivateActiveSection() {
    let currentActiveTab = this.container.querySelector('.dd-keyfigures__tab--active');
    let currentActiveSection = this.container.querySelector('.dd-keyfigures__section--active');

    if (currentActiveTab) currentActiveTab.classList.remove('dd-keyfigures__tab--active');
    if (currentActiveSection) currentActiveSection.classList.remove('dd-keyfigures__section--active');
  }

  initYearFilters() {
    this.sections.forEach(section => {
      let sectionID = section.getAttribute('id');
      let yearCheckboxes = section.querySelectorAll('.dd-keyfigures__years .dd-keyfigures__year-checkbox');
      let allYearsToggle = section.querySelector('.dd-keyfigures__years .dd-keyfigures__all-years-toggle');

      yearCheckboxes.forEach(checkbox => {
        checkbox.addEventListener('change', () => {
          let selection = [...yearCheckboxes].map(checkbox => checkbox.checked)
          this.updateChartData(sectionID, selection)
        })
      })

      allYearsToggle.addEventListener('click', () => {
        yearCheckboxes.forEach(checkbox => {
          checkbox.checked = true
        })
        let selection = [...yearCheckboxes].map(checkbox => checkbox.checked)
        this.updateChartData(sectionID, selection)
      })
    })
  }

  updateChartData(sectionID, selection) {
    let chart = this.charts[sectionID];
    let newData = JSON.parse(JSON.stringify(this.data[sectionID].data));

    newData.datasets.forEach(dataset => {
      dataset.data = dataset.data.filter((item, index) => selection[index]);
      if (dataset.footnote) dataset.footnote = dataset.footnote.filter((item, index) => selection[index]);
    });
    newData.labels = newData.labels.filter((label, index) => selection[index]);

    chart.config.data = newData;
    chart.update();
  }
}
