import { gsap } from "gsap";

export default class Graphic {
	graphic: any;

  constructor() {
   	if (document.querySelector('svg#grafik')) {

		this.graphic = document.querySelector('svg#grafik')
	   	this.wifiLines = this.graphic.querySelectorAll('#wifi path')
	   	this.lkwRecycle = this.graphic.querySelector('#lkw-recycle')
	   	this.battery = this.graphic.querySelectorAll('#battery path')
	   	this.flash = this.graphic.querySelector('#flash')
	   	this.earth = this.graphic.querySelector('#earth')
	   	this.earthShadow = this.graphic.querySelector('#earth-shadow')
	   	this.forksUpper = this.graphic.querySelector('#forks-upper')
	   	this.forksLower = this.graphic.querySelector('#forks-lower')
	   	this.chartContent = this.graphic.querySelectorAll('#chart-content path')
	   	this.leafLeft = this.graphic.querySelector('#leave-left')
	   	this.leafRight = this.graphic.querySelector('#leave-right')
	   	this.lamp = this.graphic.querySelector('#lamp-blink')
	   	this.machine = this.graphic.querySelector('#machine-small')

	   	this.dotsPulse = this.graphic.querySelectorAll('.dot--pulse')
	   	this.dots = this.graphic.querySelectorAll('.dot')


		this.animation()

		gsap.set(this.dots, {scale: 1.5, transformOrigin: '50% 50%'})
   	}


  }

  animation() {
  	gsap.fromTo(this.wifiLines, {autoAlpha: 0}, {autoAlpha: 1, stagger: {each: 1, from: 'end'}, repeat: -1, duration: 2, yoyo: true})
  	gsap.fromTo(this.battery, {autoAlpha: 0}, {stagger: {each: .8, from: 'start'}, repeat: -1, autoAlpha: 1, ease: 'linear', yoyo: true})
  	gsap.to(this.flash, {yPercent: -10, repeat: -1, ease: 'linear', yoyo: true, duration: 1})
  	gsap.to(this.earth, {yPercent: -10, repeat: -1, ease: 'linear', yoyo: true, duration: 2})
  	gsap.from(this.earthShadow, {opacity: .9, scale: .8, repeat: -1, ease: 'linear', yoyo: true, duration: 2, transformOrigin: '50% 50%'})
  	gsap.to(this.forksUpper, {yPercent: -50, repeat: -1, repeatDelay: 3, ease: 'linear', yoyo: true, duration: 2})
  	gsap.to(this.forksLower, {opacity: .5, repeat: -1, repeatDelay: 3, ease: 'linear', yoyo: true, duration: 2})
  	gsap.from(this.chartContent, {stagger: {each: .2, from: 'end'}, autoAlpha: 0, repeat: -1, repeatDelay: 2, ease: 'linear', yoyo: true, duration: 2})
  	gsap.from(this.leafLeft, {rotate: 10, delay: .5, repeat: -1, repeatDelay: 3, ease: 'bounce.out', yoyo: true, duration: 1, transformOrigin: '100% 100%'})
  	gsap.to(this.lamp, {autoAlpha: 0, repeat: -1, ease: 'linear', yoyo: true, duration: .5})
  	gsap.to(this.machine, {xPercent: 240, yPercent: 165, repeat: -1, repeatDelay: 2, ease: 'linear', yoyo: true, duration: 4})

  	gsap.to(this.dotsPulse, {stagger: {each: .2, from: 'start'}, scale: 6, autoAlpha: 0, repeat: -1, repeatDelay: 1, ease: 'linear', duration: .5, transformOrigin: '50% 50%'})

  }
}

