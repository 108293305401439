export default class Header {
  header: any;
  handleOutsideClick: (e: any) => void;
  navIsOpen: boolean = false;
  burger: any;

  constructor() {
    this.header = document.querySelector('.header');
    this.burger = document.querySelector('.burger');

    if (!this.header) return;

    if (this.burger)
      this.initBurger();

    this.initNavItems();
    this.initOutSideClick();
  }

  initBurger() {
    let that = this

    this.burger.addEventListener('click', function(e) {
      let nav = that.header.querySelector('.header__nav')
      that.closeNav()


      if(nav.classList.contains('is-active')) {
        nav.classList.remove('is-active')
        e.target.classList.remove('is-active')
      } else {
        nav.classList.add('is-active')
        e.target.classList.add('is-active')
      }
    })
  }

  initNavItems() {
    let expandableNavItems = this.header.querySelectorAll('[data-target]');

    expandableNavItems.forEach(navItem => {
      let navLink = navItem.querySelector(':scope > .header__nav-link');
      let targetSubnav = this.header.querySelector(`[data-hook=${navItem.dataset.target}]`);

      if (targetSubnav) {
        navLink.addEventListener('click', () => {
          let navLinkActive = navLink.classList.contains('header__nav-link--active');

          if (navLinkActive) {
            this.closeNav();
          } else {
            if (this.navIsOpen) this.closeNav();
            targetSubnav.classList.add('header__subnav--active');
            navLink.classList.add('header__nav-link--active');
            this.openNav();
          }
        });
      }
    });
  }

  initOutSideClick() {
    this.header.querySelectorAll('[data-hook=anchor-link]').forEach(link => {
      link.addEventListener('click', this.handleOutsideClick = (e) => {
        if(this.header.querySelector('.header__nav').classList.contains('is-active'))
          this.header.querySelector('.header__nav').classList.remove('is-active')
        if(this.burger.classList.contains('is-active'))
          this.burger.classList.remove('is-active')
        this.closeNav()
      })
    })

    window.addEventListener('click', this.handleOutsideClick = (e) => {
      if (this.navIsOpen && !this.header.contains(e.target)) {
        this.closeNav();
      }
    });
  }

  openNav() {
    this.navIsOpen = true;
    let activeNav = this.header.querySelectorAll('.is-active');

    activeNav.forEach(nav => { nav.classList.remove('is-active') });
    this.header.classList.add('header--expanded');
  }

  closeNav() {
    let activeNavLinks = [...this.header.querySelectorAll('.header__nav-link--active')];
    let activeSubnavs = [...this.header.querySelectorAll('.header__subnav--active')];

    activeNavLinks.forEach(link => { link.classList.remove('header__nav-link--active') });
    activeSubnavs.forEach(subnav => { subnav.classList.remove('header__subnav--active') });
    this.header.classList.remove('header--expanded');
    this.navIsOpen = false;
  }
}
