{
  "de": {
    "units": {
      "unit": "Stück",
      "units": "Stück",
      "euro": "Euro",
      "mio_euro": "Mio. €",
      "euro_short": "€",
      "percent": "Prozent",
      "percent_short": "%",
      "years": "Jahre"
    },
    "misc": {
      "bars": "Säulen",
      "lines": "Linien"
    },
    "labels": {
      "incoming_orders": "Auftragseingang",
      "orders_on_hand": "Auftragsbestand 31.12.",
      "revenue": "Umsatzerlöse",
      "thereof_germany": "davon Inland",
      "thereof_abroad": "davon Ausland",
      "foreign_ratio": "Auslandsquote",
      "ebit": "Ergebnis vor Finanzergebnis und Ertragsteuern (EBIT)",
      "ebit_ros": "EBIT-Rendite (EBIT-ROS)",
      "roce": "EBIT-Kapitalrendite (ROCE)",
      "roce_new": "EBIT-Kapitalrendite Intralogistik (ROCE-neu)",
      "ebt": "Ergebnis vor Steuern (EBT)",
      "ebt_ros": "EBT-Rendite (EBT-ROS)",
      "profit_or_loss": "Ergebnis nach Steuern",
      "capital_expenditure": "Investitionen",
      "rnd_expenditure": "Ausgaben für Forschung und Entwicklung",
      "balance_sheet_total": "Bilanzsumme 31.12.",
      "shareholders_equity": "Eigenkapital 31.12.",
      "thereof_subscribed_capital": "davon gezeichnetes Kapital",
      "employees": "Mitarbeitende 31.12.",
      "earnings_preferred_share": "Ergebnis je Vorzugsaktie",
      "dividend_ordinary_share": "Dividende je Aktie – Stammaktie",
      "dividend_preferred_share": "Dividende je Aktie – Vorzugsaktie",
    },
    "footnotes": {
      "1": "¹ EBIT in % auf das eingesetzte zinspflichtige Kapital (Stichtag)",
      "2": "² EBIT des Segmentes „Intralogistik“ in % zum durchschnittlich gebundenen Kapital des Segmentes „Intralogistik“ ",
      "3": "³ Sachanlagen und immaterielle Vermögenswerte ohne aktivierte Entwicklungsausgaben und Nutzungsrechte",
      "4": "⁴ FTE = Full Time Equivalents (Vollzeitäquivalente; bei der Ermittlung wurden Teilzeitkräfte entsprechend ihrer Arbeitszeit berücksichtigt)",
      "5": "⁵ Auf Basis des Ergebnisanteiles der Aktionärinnen und Aktionäre der Jungheinrich AG",
      "6": "⁶ Vorschlag"
    }
  }
}
