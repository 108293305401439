import de from "../locale/de.json";
import en from "../locale/en.json";

export default class I18N {
  lang: string;
  translations: Object;
  localeIDs: Object;

  constructor() {
    this.lang = document.querySelector('html').getAttribute('lang')
    this.translations = { ...de, ...en }
    this.localeIDs = {
      'de': 'de-DE',
      'en': 'en-US'
    }
  }

  t(key) {
    let translations = this.translations[this.lang]
    let value = key.split('.').reduce(function(p,prop) { return p[prop] }, translations);
    return value
  }

  getLocaleID() {
    return this.localeIDs[this.lang];
  }
}
