export default class PDFLinks {
  PDFLinks: Element[];

  constructor () {
    this.PDFLinks = [...document.querySelectorAll('[data-hook=pdf-link]')];

    const userAgent = navigator.userAgent || "";
    const platform = navigator.platform || "";
    const maxTouchPoints = navigator.maxTouchPoints || 1;
    const isIOS = /\b(iPad|iPhone|iPod)(?=;)/.test(userAgent) || (platform === "MacIntel" && maxTouchPoints > 1);

    if (isIOS) {
      this.PDFLinks.forEach(link => {
        this.initPDFLink(link);
      });
    }
  }

  initPDFLink(link) {
    let href = link.getAttribute('href');
    let newHref = href.replace('#page=', '#page');

    link.setAttribute('href', newHref)
  }
}
