export default class Share {
  shareTrigger: any;

  constructor() {
    this.shareTrigger = document.querySelector('.side-actions__share')
    if(!this.shareTrigger) return

    this.shareTrigger.addEventListener('click', e => {
      e.target.classList.toggle('is-active')
    })
  }
}
