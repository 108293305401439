export default class Drawer {
  drawer: any;
  body: HTMLBodyElement;
  handleOutsideClick: (e: any) => void;

  constructor() {
    this.drawer = document.querySelector('[data-hook=drawer]');
    if (!this.drawer) return;

    this.body = document.querySelector('body');

    this.initToggles();
    this.drawer.classList.add('drawer--initialized');
  }

  initToggles() {
    let drawerOpenButtons = document.querySelectorAll('[data-drawer-trigger]');
    let drawerCloseButtons = document.querySelectorAll('[data-hook=close-drawer]');

    drawerOpenButtons.forEach(drawerOpenButton => {
      drawerOpenButton.addEventListener('click', (e) => {
        e.preventDefault();
        this.closeDrawer(e);
        this.openDrawer(e);
      })
    })

    drawerCloseButtons.forEach(drawerCloseButton => {
      drawerCloseButton.addEventListener('click', (e) => {
        e.preventDefault();
        this.closeDrawer();
      })
    })
  }

  openDrawer(event) {
    this.drawer.classList.add('drawer--open');
    this.showContent(event);
    this.addOutsideClickListener();
    this.lockViewport();
  }

  showContent(event) {
    let theme = event.target.getAttribute('data-drawer-trigger')
    let drawer = document.querySelector(`[data-drawer-theme=${theme}]`)
    drawer.classList.add('is-active')
  }

  closeDrawer() {
    let actives = document.querySelectorAll('.is-active')
    actives.forEach(active => {
      active.classList.remove('is-active')
    })

    this.drawer.classList.remove('drawer--open');
    this.drawer.classList.remove('drawer--add-to-playlist');
    this.removeOutsideClickListener();
    this.unlockViewport();
  }

  addOutsideClickListener() {
    this.drawer.addEventListener('click', this.handleOutsideClick = (e) => {
      let target = e.target;
      if (target.classList.contains('drawer')) this.closeDrawer();
    })
  }

  removeOutsideClickListener() {
    this.drawer.addEventListener('click', this.handleOutsideClick);
  }

  lockViewport() {
    this.body.classList.add('overflow-hidden');
  }

  unlockViewport() {
    this.body.classList.remove('overflow-hidden');
  }
}
