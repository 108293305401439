import settings from 'bundle-text:../../settings.module.sass';
import I18N from '../i18n';

const i18n = new I18N();

const furtherKeyFigures = {
  labels: [
    '2017',
    '2018',
    '2019',
    '2020',
    '2021'
  ],
  datasets: [
    {
      label: `${i18n.t('labels.capital_expenditure')} ³`,
      unit: i18n.t('units.mio_euro'),
      backgroundColor: '#000',
      borderColor: '#000',
      data: [88, 106, 157, 75, 71],
      order: 2
    }, {
      label: i18n.t('labels.rnd_expenditure'),
      unit: i18n.t('units.mio_euro'),
      backgroundColor: '#636B6E',
      borderColor: '#636B6E',
      data: [77, 84, 86, 89, 102],
      order: 2
    }, {
      label: i18n.t('labels.balance_sheet_total'),
      unit: i18n.t('units.mio_euro'),
      backgroundColor: '#B1B5B7',
      borderColor: '#B1B5B7',
      data: [4130, 4746, 5231, 5411, 5769],
      order: 2
    }, {
      label: i18n.t('labels.shareholders_equity'),
      unit: i18n.t('units.mio_euro'),
      backgroundColor: '#D7DADB',
      borderColor: '#D7DADB',
      data: [1244, 1362, 1488, 1546, 1803],
      order: 2
    }, {
      label: i18n.t('labels.thereof_subscribed_capital'),
      unit: i18n.t('units.mio_euro'),
      backgroundColor: '#EAEBEB',
      borderColor: '#EAEBEB',
      indent: true,
      data: [102, 102, 102, 102, 102],
      order: 2
    }, {
      type: 'line',
      label: `${i18n.t('labels.earnings_preferred_share')} ⁵`,
      unit: '€',
      minimumFractionDigits: 2,
      backgroundColor: '#FFB900',
      borderColor: '#FFB900',
      data: [1.80, 1.73, 1.75, 1.49, 2.62],
      order: 1,
      yAxisID: 'yRight'
    }, {
      type: 'line',
      label: i18n.t('labels.dividend_ordinary_share'),
      unit: '€',
      minimumFractionDigits: 2,
      backgroundColor: '#FFDD96',
      borderColor: '#FFDD96',
      data: [0.48, 0.48, 0.46, 0.41, 0.66],
      footnote: [null, null, null, null, '⁶'],
      order: 1,
      yAxisID: 'yRight'
    }, {
      type: 'line',
      label: i18n.t('labels.dividend_preferred_share'),
      unit: '€',
      minimumFractionDigits: 2,
      backgroundColor: '#BA8800',
      borderColor: '#BA8800',
      data: [0.50, 0.50, 0.48, 0.43, 0.68],
      footnote: [null, null, null, null, '⁶'],
      order: 1,
      yAxisID: 'yRight'
    }
  ]
};

const furtherKeyFiguresOptions = {
  scales: {
    x: {
      ticks: {
        font: {
          size: 24,
          weight: 500
        }
      },
      grid: {
        borderWidth: 2,
        display: false,
        borderColor: '#3c464b'
      }
    },
    yLeft: {
      type: 'linear',
      position: 'left',
      grid: {
        borderWidth: 2,
        borderDash: [2, 2],
        tickBorderDash: [2, 2]
      },
      title: {
        display: true,
        text: `${i18n.t('units.mio_euro')} (${i18n.t('misc.bars')})`,
        font: {
          size: 14,
          family: settings['font-stack-sans'],
          weight: 500
        }
      },
      ticks: {
        maxTicksLimit: 6,
      }
    },
    yRight: {
      type: 'linear',
      position: 'right',
      grid: {
        borderWidth: 2,
        color: '#ffb900',
        borderColor: '#ffb900',
      },
      title: {
        display: true,
        text: `in € (${i18n.t('misc.lines')})`,
        font: {
          size: 14,
          family: settings['font-stack-sans'],
          weight: 500
        }
      },
      ticks: {
        maxTicksLimit: 6
      }
    }
  },
  footnotes: [
    i18n.t('footnotes.3'),
    i18n.t('footnotes.5'),
    i18n.t('footnotes.6')
  ]
}

export { furtherKeyFigures, furtherKeyFiguresOptions }
