import settings from 'bundle-text:../../settings.module.sass';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js"
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js"

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

ScrollTrigger.matchMedia({

  // mobile
  "(max-width: 79.999em)": function () {

    document.addEventListener("DOMContentLoaded", function (event) {
      if (document.querySelector('.timeline__canvas')) {

        let panels = document.querySelectorAll('.timeline__panel');
        const navLis = document.querySelectorAll(".timeline__nav a");

        panels.forEach(panel => {
          let tlPanel = gsap.timeline({
            scrollTrigger: {
              trigger: panel,
              toggleActions: "play reverse play reverse",
              start: `top center`,
              onUpdate: (self) => {
                navLis.forEach(link => {
                  link.classList.remove("is-active");
                  if (link.getAttribute("data-hook") == panel.id) {
                    link.classList.add("is-active");
                  }
                })
              },
            },
            defaults: {
              ease: "none",
              duration: 1,
            }
          })
        })
      }
    }) // document loaded

  },
  "(min-width: 80em)": function () {

    document.addEventListener("DOMContentLoaded", function (event) {
      if (document.querySelector('.timeline__canvas')) {

        let panels = document.querySelectorAll('.timeline__panel');
        const navLis = document.querySelectorAll(".timeline__nav a");

        panels.forEach(panel => {
          let text = panel.querySelector('.timeline__text');
          let image = panel.querySelector('.timeline__img img');
          let panelId = panel.getAttribute('id')
          let targetLink = document.querySelector(`.timeline__nav a#${panelId}`);

          let tlPanel = gsap.timeline({
            scrollTrigger: {
              trigger: panel,
              toggleActions: "play reverse play reverse",
              start: `top center`,
              onUpdate: (self) => {
                navLis.forEach(link => {
                  link.classList.remove("is-active");
                  if (link.getAttribute("data-hook") == panel.id) {
                    link.classList.add("is-active");
                  }
                })
              },
            },
            defaults: {
              ease: "none",
              duration: 1,
            }
          })

          if (panel.querySelector('.timeline__content--right')) {
            let contentRight = panel.querySelector('.timeline__content--right')

            let animContentRight = gsap.fromTo(contentRight, {
              autoAlpha: 0,
              xPercent: 100
            }, {
              duration: 1,
              autoAlpha: 1,
              xPercent: 0
            })

            tlPanel.add(animContentRight)
          }

          if (panel.querySelector('.timeline__content--left')) {
            let contentLeft = panel.querySelector('.timeline__content--left')

            let animContentLeft = gsap.fromTo(contentLeft, {
              autoAlpha: 0,
              xPercent: -100
            }, {
              duration: 1,
              autoAlpha: 1,
              xPercent: 0
            })

            tlPanel.add(animContentLeft)
          }

          if (panel.querySelector('.timeline__img--left')) {
            let imgLeft = panel.querySelector('.timeline__img--left')
            let textContainerRight = panel.querySelector('.timeline__text--right')
            let textRight = textContainerRight.querySelectorAll('p')

            let animImgLeft = gsap.fromTo(imgLeft, {
              clipPath: "inset(0% 100% 0% 0%)"
            }, {
              clipPath: "inset(0% 0% 0% 0%)"
            })

            tlPanel.add(animImgLeft, "-=.5")
          }


          if (panel.querySelector('.timeline__img--right')) {
            let imgRight = panel.querySelector('.timeline__img--right')
            let textContainerLeft = panel.querySelector('.timeline__text--left')
            let textLeft = textContainerLeft.querySelectorAll('p')

            let animImgRight = gsap.fromTo(imgRight, {
              clipPath: "inset(0% 0% 0% 100%)"
            }, {
              clipPath: "inset(0% 0% 0% 0%)"
            })

            tlPanel.add(animImgRight, "-=.5")
          }

          if (panel.querySelector('.timeline__img--scale')) {
            let imgScale = panel.querySelector('.timeline__img--scale img')
            let animImgScale = gsap.to(imgScale, {
              duration: 1,
              scale: 1.3
            })

            tlPanel.add(animImgScale, "-=.5")
          }

          if (panel.querySelector('.timeline__img--scale-out')) {
            let imgScaleOut = panel.querySelector('.timeline__img--scale-out img')
            let animImgScaleOut = gsap.from(imgScaleOut, {
              duration: 1,
              scale: 1.3
            })

            tlPanel.add(animImgScaleOut, "-=.5")
          }

          if (panel.querySelector('.strip__canvas')) {
            let strip = panel.querySelector('.strip__canvas')
            let animStrip = gsap.fromTo(strip, {
              autoAlpha: 0
            }, {
              duration: 1,
              autoAlpha: 1
            })

            tlPanel.add(animStrip, "-=.5")
          }

          if (panel.querySelector('.timeline__img--overlay')) {
            let svg = panel.querySelector('.timeline__img--overlay svg')
            let animSvgReveal = gsap.fromTo(svg, {
              autoAlpha: 0
            }, {
              duration: 1,
              autoAlpha: 1
            })


            if (svg.querySelector('#circle')) {
              let circle = svg.querySelector('#circle')
              tlPanel.add(animSvgReveal)
              gsap.to(circle, {
                scale: .95,
                repeat: -1,
                yoyo: true,
                duration: 1.5,
                transformOrigin: '50% 50%',
                ease: 'linear'
              })
            }

            if (panel.querySelector('#info-panel')) {
              let infoPanel = svg.querySelector('#info-panel')
              let lineTop = svg.querySelector('#top-line')
              let lineBottom = svg.querySelector('#bottom-line')
              let middle = svg.querySelector('#middle')

              let moveTop = gsap.from(lineTop, {
                y: 40,
                autoAlpha: 0
              })
              let moveBottom = gsap.from(lineBottom, {
                y: -40,
                autoAlpha: 0
              })
              let revealText = gsap.from(middle, {
                autoAlpha: 0
              })

              tlPanel.add(moveTop).add(moveBottom, "<").add(revealText)
            }
          }
        })

        // STICKY MENU //
        let canvas = document.querySelector('.timeline__canvas')
        let stickyMenu = document.querySelector('.timeline')

        gsap.fromTo(stickyMenu, {
          autoAlpha: 0,
          yPercent: -100
        }, {
          autoAlpha: 1,
          yPercent: 0,
          scrollTrigger: {
            trigger: canvas,
            toggleActions: "play none none reverse",
            start: "300 center",
            end: "bottom center"

          }
        });
      } // if
    }) // document loaded
  }
}) // mq
