class Accordion {
  accordion: any;
  closer: any;

  constructor() {
    this.accordion = document.querySelectorAll('.accordion')
    if (this.accordion.length < 1) return

    this.accordion.forEach(accordion => {
      if (accordion.getAttribute('data-accordion') == 'multi')
        this.initMulti(accordion)
      else
        this.initNormal(accordion)
    })

    this.closer = document.querySelectorAll('.accordion__close')
    if(this.closer.length > 0)
      this.initClose()
  }

  initNormal(accordion) {
    let items = accordion.querySelectorAll('.accordion__item')
    items.forEach(item => {
      let headline = item.querySelector('.accordion__headline')
      headline.addEventListener('click', () => {
        let active = document.querySelector('.accordion__item--is-active')
        if (active)
          active.classList.remove('accordion__item--is-active')

        if (item != active)
          item.classList.add('accordion__item--is-active')
      })
    })
  }

  initMulti(accordion) {
    let items = accordion.querySelectorAll('.accordion__item')
    items.forEach(item => {
      let headline = item.querySelector('.accordion__headline')
      headline.addEventListener('click', () => {
        item.classList.toggle('accordion__item--is-active')
      })
    })
  }

  initClose() {
    this.closer.forEach(close => {
      close.addEventListener('click', (e) => {
        document.querySelector('.accordion__item--is-active').classList.remove('accordion__item--is-active')
      })
    })
  }
}

export default Accordion;
