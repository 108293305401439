import settings from 'bundle-text:../settings.module.sass';

export default class ScrollTo {
  anchorLinks: any;
  headerHeight: number = parseInt(settings['header-height']);
  autoScrollActiveEvent: Event;

  constructor () {
    this.anchorLinks = [...document.querySelectorAll('[data-hook=anchor-link]')];
    this.autoScrollActiveEvent = new Event('autoscroll:active');

    this.anchorLinks.forEach(link => {
      this.initAnchorLink(link);
    });
    this.initAutoScroll();
  }

  initAutoScroll() {
    let params = new URLSearchParams(location.search);
    let autoScroll = parseInt(params.get("as"));
    let targetElement = document.querySelector('[data-hook=autoscroll-target]');

    if (autoScroll === 1 && targetElement) {
      window.dispatchEvent(this.autoScrollActiveEvent);
      window.onload = () => {
        this.scrollToPosition(targetElement);
      }
    }
  }

  initAnchorLink(link) {
    link.addEventListener('click', (e) => {
      let targetID = e.target.getAttribute('href').split('#')[1];
      let targetElement = document.querySelector(`#${targetID}`);

      this.scrollToPosition(targetElement);
      e.preventDefault();
    })
  }

  scrollToPosition(targetElement) {
    let coverViewportOffset = targetElement.getBoundingClientRect().top;
    let windowScrollY = window.pageYOffset;
    let headerHeight = this.headerHeight;
    let scrollPos = windowScrollY + coverViewportOffset - headerHeight
    window.scrollTo({
      top: scrollPos,
      behavior: 'smooth'
    });
  }
}
