{
  "en": {
    "units": {
      "unit": "Unit",
      "units": "Units",
      "euro": "Euro",
      "mio_euro": "€ million",
      "euro_short": "€",
      "percent": "Percent",
      "percent_short": "%",
      "years": "Years"
    },
    "misc": {
      "bars": "Bars",
      "lines": "Lines"
    },
    "labels": {
      "incoming_orders": "Incoming orders",
      "orders_on_hand": "Orders on hand 31/12",
      "revenue": "Revenue",
      "thereof_germany": "thereof Germany",
      "thereof_abroad": "thereof abroad",
      "foreign_ratio": "Foreign ratio",
      "ebit": "Earnings before interest and income taxes (EBIT)",
      "ebit_ros": "EBIT return on sales (EBIT ROS)",
      "roce": "EBIT return on capital employed (ROCE)",
      "roce_new": "EBIT return on capital employed Intralogistics (ROCE new)",
      "ebt": "Earnings before taxes (EBT)",
      "ebt_ros": "EBT return on sales (EBT ROS)",
      "profit_or_loss": "Profit or loss",
      "capital_expenditure": "Capital expenditure",
      "rnd_expenditure": "Research and development expenditure",
      "balance_sheet_total": "Balance sheet total 31/12",
      "shareholders_equity": "Shareholders’ equity 31/12",
      "thereof_subscribed_capital": "thereof subscribed capital",
      "employees": "Employees 31/12",
      "earnings_preferred_share": "Earnings per preferred share",
      "dividend_ordinary_share": "Dividend per share – ordinary share",
      "dividend_preferred_share": "Dividend per share – preferred share",
    },
    "footnotes": {
      "1": "¹ EBIT as a percentage of interest-bearing capital employed (cut-off date).",
      "2": "² EBIT of the “Intralogistics” segment as a percentage of average capital employed of the “Intralogistics” segment.",
      "3": "³ Property, plant and equipment and intangible assets without capitalised development expenditure and right-of-use assets.",
      "4": "⁴ FTE = full-time equivalents; part-time employees were taken into account according to their hours.",
      "5": "⁵ Based on share of earnings attributable to the shareholders of Jungheinrich AG.",
      "6": "⁶ Proposal."
    }
  }
}
