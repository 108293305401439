import DDKeyFigures from '../../lib/dd-keyfigures/dd-keyfigures'
import settings from 'bundle-text:../../settings.module.sass';
import I18N from '../i18n';

import { orders, ordersOptions } from "../chart-data/orders";
import { revenue, revenueOptions } from "../chart-data/revenue";
import { incomeStatement, incomeStatementOptions } from "../chart-data/income-statement";
import { furtherKeyFigures, furtherKeyFiguresOptions } from "../chart-data/further-key-figures";
import { employees, employeesOptions } from "../chart-data/employees";
import { overview, overviewOptions } from "../chart-data/overview";

const i18n = new I18N();

const config = {
  'font-family': settings['font-stack-slab'],
}

const legendMargin = {
  id: 'legendMargin',
  beforeInit(chart, legend, options) {
    const fitValue = chart.legend.fit;
    chart.legend.fit = function fit() {
      fitValue.bind(chart.legend)();
      return this.height += 30;
    }
  }
}

const globalOptions = {
  locale: i18n.getLocaleID(),
  maintainAspectRatio: false,
  animation: false,
  maxBarThickness: 20,
  plugins: {
    legend: {
      title: {
        font: {
          size: 18,
          family: settings['font-stack-sans'],
          weight: 700,
        }
      },
      labels: {
        textAlign: 'left',
        padding: 20,
        font: {
          size: 14,
          family: settings['font-stack-sans'],
          weight: 500,
        },
      }
    },
    tooltip: {
      titleFont: {
        size: 12,
        weight: 500
      },
      bodyFont: {
        size: 14,
        weight: 500,
        family: settings['font-stack-sans'],
      },
      mode: 'index',
      usePointStyle: true,
      pointStyle: 'rectRounded',
      pointRadius: 3,
      callbacks: {
        labelPointStyle: function() {
          return {
            pointStyle: 'rectRounded',
            pointRadius: 3
          };
        },
        label: function (context) {
          let dataset = context.dataset;
          let label = dataset.label || '';
          let options = {}
          let localeID = i18n.getLocaleID()

          if (dataset.minimumFractionDigits !== null) {
            options = {
              ...options,
              ...{ minimumFractionDigits: dataset.minimumFractionDigits }
            }
          }
          if (label) {
            label += ':  ';
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat(localeID, options).format(context.parsed.y);
          }
          if (context.dataset.unit !== null) {
            label += ` ${context.dataset.unit}`
          }
          if (dataset.footnote !== undefined) {
            let y = context.parsed.y
            let footnote = dataset.footnote[dataset.data.indexOf(y)]
            if (footnote) label += ` ${footnote}`
          }
          return label;
        }
      }
    }
  }
}

const data = {
  'orders': {
    type: 'bar',
    data: orders,
    options: {...globalOptions, ...ordersOptions},
    plugins: [legendMargin]
  },
  'revenue': {
    type: 'bar',
    data: revenue,
    options: {...globalOptions, ...revenueOptions},
    plugins: [legendMargin]
  },
  'income-statement': {
    type: 'bar',
    data: incomeStatement,
    options: {...globalOptions, ...incomeStatementOptions},
    plugins: [legendMargin]
  },
  'further-key-figures': {
    type: 'bar',
    data: furtherKeyFigures,
    options: {...globalOptions, ...furtherKeyFiguresOptions},
    plugins: [legendMargin]
  },
  'employees': {
    type: 'bar',
    data: employees,
    options: {...globalOptions, ...employeesOptions},
    plugins: [legendMargin]
  },
  'overview': {
    type: 'bar',
    data: overview,
    options: {...globalOptions, ...overviewOptions},
    plugins: [legendMargin]
  },
}

const keyfigures = new DDKeyFigures(data, config);
