// ============================================================================
// SETTINGS MODULE
// ============================================================================
// Can be imported into JavaScript files to use the Sass variables there
// import settings from 'bundle-text:../settings.module.sass'
// console.log(settings)
// ============================================================================

@import "styles/settings/design-tokens"

:export
  header-height: $header-height
  font-stack-sans: $font-stack-sans
  font-stack-slab: $font-stack-slab
  breakpoint-phablet: map-get($breakpoints, 'phablet')
  breakpoint-tablet: map-get($breakpoints, 'tablet')
  breakpoint-notebook: map-get($breakpoints, 'tablet-ls')
  breakpoint-desktop: map-get($breakpoints, 'desktop')
  breakpoint-widescreen: map-get($breakpoints, 'widescreen')
  breakpoint-extra-wide: map-get($breakpoints, 'xtra-wide')
