import Plyr from 'plyr';

export default class Video {
  player: Plyr;
  imageUrl: URL;
  videos: NodeListOf<Element>;

  constructor() {
    this.videos = document.querySelectorAll('[data-hook=video-player]');
    if(this.videos.length < 1) return;

    this.imageUrl = new URL(
      '/node_modules/plyr/dist/plyr.svg',
      import.meta.url
    );

    this.videos.forEach((video) => {
      this.player = new Plyr(video, {
        iconUrl: this.imageUrl,
        controls: ['play-large', 'play', 'progress', 'current-time', 'fullscreen'],
      });
    })
  }
}
