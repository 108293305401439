import settings from 'bundle-text:../../settings.module.sass';

export default class Media {
  breakpoints: Object;
  constructor() {
    this.breakpoints = {
      'phablet': parseFloat(settings['breakpoint-phablet']),
      'tablet': parseFloat(settings['breakpoint-tablet']),
      'tablet-ls': parseFloat(settings['breakpoint-notebook']),
      'desktop': parseFloat(settings['breakpoint-desktop']),
      'wide': parseFloat(settings['breakpoint-widescreen']),
      'xtra-wide': parseFloat(settings['breakpoint-xtra-wides']),
    }
  }

  from(breakpoint) {
    if (!(breakpoint in this.breakpoints)) return;
    let mediaQuery = window.matchMedia(`(min-width: ${this.breakpoints[breakpoint]}em)`);
    return mediaQuery.matches;
  }

  until(breakpoint) {
    if (!(breakpoint in this.breakpoints)) return;
    let mediaQuery = window.matchMedia(`(max-width: ${(this.breakpoints[breakpoint]) - 0.01}em)`);
    return mediaQuery.matches;
  }
}
