import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class Interview {
  pos: string;
  arculeeToBubbleSpacing: number = 8;
  arculeeToWayPointSpacing: number = 30;

  constructor() {
    let interview = document.querySelector('[data-hook=interview]');
    if (!interview) return;

    let interviewSections = [...interview.querySelectorAll('[data-hook=interview-section]')]
    gsap.registerPlugin(ScrollTrigger)

    document.addEventListener("DOMContentLoaded", () => {
      interviewSections.forEach(interviewSection => {
        this.initToggles(interviewSection);
      });
      interview.classList.add('interview--initialized');
    });
  }

  initToggles(interviewSection) {
    let toggle = interviewSection.querySelector('[data-hook=interview-toggle]');
    let sectionModifier = 'interview__section--collapsed';
    let toggleModifier = 'more-button--up';

    if(toggle) {
      interviewSection.classList.add(sectionModifier)
      toggle.addEventListener('click', () => {
        let sectionIsCollapsed = interviewSection.classList.contains('interview__section--collapsed');
        let sectionIsInitialized = interviewSection.classList.contains('interview__section--initialized');

        if (sectionIsCollapsed && !sectionIsInitialized) {
          window.setTimeout(() => {
            this.initSection(interviewSection);
          }, 500);
        }
        interviewSection.classList.toggle(sectionModifier);
        toggle.classList.toggle(toggleModifier);
      })
    }
  }

  initSection(interviewSection) {
    let arculee = interviewSection.querySelector('.interview__pointer');
    let wayPoints = [...interviewSection.querySelectorAll('.interview__visual, .interview__answer, .interview__question--followup')];
    let sectionBlocks = [...interviewSection.querySelectorAll('.interview__visual, .interview__answers, .interview__question--followup')];
    let sectionBlocksHeights = sectionBlocks.map(child => child.offsetHeight)
    let sectionBlocksHeight = sectionBlocksHeights.reduce((a, b) => a + b);
    let sectionTimeline = gsap.timeline();

    interviewSection.style = `--content-max-height: ${sectionBlocksHeight + 100}px`;
    interviewSection.classList.add('interview__section--initialized')

    wayPoints.forEach((wayPoint, index) => {
      this.initWayPoint(wayPoint, index, arculee, sectionTimeline);
    })
  }

  initWayPoint(wayPoint, index, arculee, sectionTimeline) {
    let bubble = wayPoint.querySelector('.interview__bubble');
    let trigger = bubble ? bubble : wayPoint;
    let bubbleXTravel = wayPoint.classList.contains('interview__answer--even') ? -15 : 15;
    let arculeeXTravel = wayPoint.classList.contains('interview__answer--even') ? 18 : -18;
    let startTrigger = bubble ? 'bottom bottom-=70px' : 'bottom 80%';

    ScrollTrigger.create({
      trigger: trigger,
      start: startTrigger,
      onEnter: () => {
        if (trigger.dataset.played) return;
        trigger.setAttribute('data-played', true);

        if (index > 0) sectionTimeline.seek(`endWayPoint${index - 1}`);

        let arculeeOffsetTarget = this.offsetPos(arculee, trigger, !!bubble);

        sectionTimeline.add(`enterWayPoint${index}`)
        .to(
          arculee, {
            y: '+=' + arculeeOffsetTarget,
            duration: 1,
          },
          `enterWayPoint${index}`
        )

        if (bubble) {

          sectionTimeline.to(
            trigger, {
              opacity: 1,
              duration: .5,
            },
            `enterWayPoint${index}`
          ).to(
            arculee, {
              xPercent: arculeeXTravel,
              duration: 1,
            }
          )
          .add(`grabIt${index}`)
          .to(
            arculee, {
              xPercent: 0,
              duration: 1,
          },
          `grabIt${index}`
          ).to(
            trigger, {
              xPercent: bubbleXTravel,
              duration: .75,
            },
            `grabIt${index}`
          ).add(`endWayPoint${index}`);

        }

        sectionTimeline.add(`endWayPoint${index}`);
      }
    })
  }

  offsetPos(arculee, triggerElement, isBubble) {
    let triggerElementPos = triggerElement.getBoundingClientRect().top;
    let triggerElementHeight = (triggerElement.classList.contains('interview__bubble--has-link')) ? triggerElement.clientHeight + 75 : triggerElement.clientHeight;
    let arculeePos = arculee.getBoundingClientRect().top;
    let spacing = isBubble ? this.arculeeToBubbleSpacing : this.arculeeToWayPointSpacing;
    let offset = triggerElementPos + triggerElementHeight - arculeePos + spacing;
    return offset;
  }
}
